.gate-img {
    width: 100%;
}

.gate-services {
    padding: 30px;
    margin-top: 10%;
}

.gate-div h2 {
    position: absolute;
    top: 30%;
    left: 10%;
    font-size: 55px;
    text-transform: uppercase;
    color: rgb(95, 69, 34);
    font-family: 'Josefin Sans', sans-serif;
    text-align: start;
    max-width: 900px;
    line-height: 1.5em;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .gate-div h2 {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1023px) {
    .gate-div h2 {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}

.gate-services h3 {
    text-transform: capitalize;
    font-size: 45px;
    font-family: poppins, sans-serif;
    font-weight: 600;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .gate-services h3 {
        text-transform: capitalize;
        font-size: 35px;
        font-family: poppins, sans-serif;
        font-weight: 400;
    }

}
.gate-services h2 {
    text-align: start;
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 40px;
    margin-top: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .gate-services h2 {
        text-align: start;
        font-size: 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        padding: 10px;
        margin-top: 30px;
    }

}
.gate-services img {
    width: 100%;
    border-radius: 25px;
}

.gate-services p {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: start;
    margin-left: 30px;
    line-height: 2em;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .gate-services p {
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        text-align: start;
        margin-left: 10px;
        line-height: 2em;
    }

}