@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Arsenal&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Jost:wght@300&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Arsenal&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Jost:wght@300&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Raleway&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Arsenal&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Jost:wght@300&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Open+Sans&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Raleway&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');

.footer-div {
    background-color: #4e704d;
    ;
    padding-bottom: 2%;
}

.footer-logo {
    margin-top: 50px;
}

.footer-content {
    margin-top: 50px;
}

.footer-content h4 {
    font-size: 30px;
    color: white;
    font-family: 'Jost', sans-serif;
    /* margin-left: 100px; */
}

@media only screen and (min-width: 300px) and (max-width: 699px) {

    .footer-content h4 {
        font-size: 30px;
        color: rgb(125, 202, 247);
        font-family: 'Jost', sans-serif;
        margin-left: -50%;
        margin-top: -60px;
    }
}

.footer-text {
    text-align: start;
    font-size: 25px;
    color: white;
    font-family: 'Jost', sans-serif;
    margin: 30px;
}

.contact-container {
    margin-left: 30%;
    margin-top: 30px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contact-container {
        margin-left: -10px;
        margin-top: -30px;
        padding: 10px;
    }
}

.icon-contact {
    color: rgb(252, 249, 249);
    margin-left: -90%;
    margin-top: 25px;
    font-size: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .icon-contact {
        color: rgb(143, 136, 136);
        margin-left: -80%;
        margin-top: 18px;
        font-size: 25px;
    }
}

.details {
    color: white;
    font-family: 'Jost', sans-serif;
    margin-top: -30px;
    text-align: start;
    margin-left: 50px;
    font-size: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .details {
        color: white;
        font-family: 'Jost', sans-serif;
        margin-top: -32px;
        text-align: start;
        margin-left: 55px;
        font-size: 18px;
    }
}

.social-container {
    display: flex;
    flex-direction: row;
    /* margin-top: 40%; */
    margin-left: 40%;
}

.icon {
    width: 80px;
    height: 90px;
    font-size: 30px;
    color: white;
    text-decoration: none;
}
.icon a {
    color: white;
}
.follow {
    font-size: 30px;
    color: white;
    font-family: 'Jost', sans-serif;
    margin-top: 50px;
    margin-left: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {

    .follow {
        font-size: 30px;
        color: rgb(125, 202, 247);
        font-family: 'Jost', sans-serif;
        margin-left: -50%;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 2000px) {
    .follow {
        font-size: 30px;
        color: white;
        font-family: 'Jost', sans-serif;
    }
}

@media only screen and (min-width: 300px) and (max-width: 699px) {

    .social-container {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        margin-left: 5%;
        color: white;
    }

}

@media only screen and (min-width: 700px) and (max-width: 1024px) {

    .social-container {
        display: flex;
        flex-direction: row;
        margin-left: -15px;
        color: white;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 2000px) {
    .social-container {
        display: flex;
        flex-direction: row;
        margin-left: 30%;
        color: white;
    }
}

.copyright {
    margin-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.qr-code {
    font-family: 'Jost', sans-serif;
    margin-top: 5px;
    color: white;
}