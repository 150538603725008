.floating-icons {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
  .floating-icons {
    position: fixed;
    bottom: 50px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;
  }

}



.whats {
  width: 10%;
  border-radius: 50%;
  width: 30px;
  height: 50px;
  margin-top: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
  /* .whats {
    width: 40px;
    height: 40px;
  } */

}

