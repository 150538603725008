@import url('https://fonts.googleapis.com/css2?family=Acme&family=Oswald&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Edu+TAS+Beginner&family=Oswald&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Edu+TAS+Beginner&family=Oswald&family=Roboto&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Oswald&family=Oxygen:wght@700&family=Roboto&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Mukta:wght@300&family=Oswald&family=Oxygen:wght@700&family=Roboto&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Mukta:wght@300&family=Oswald&family=Oxygen:wght@700&family=Roboto&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Mukta:wght@300&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Jost:wght@300&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');



.row {
    margin-right: 0px;
}

.banner-div {
    position: relative;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .banner-div img {
        height: 150px;
    }
}

.banner-div h2 {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 55px;
    text-transform: uppercase;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    text-align: start;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .banner-div h2 {
        position: absolute;
        top: 10%;
        left: 5%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
        font-weight: 100;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1023px) {
    .banner-div h2 {
        position: absolute;
        top: 10%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
        font-weight: 100;

    }
}


.experience {
    margin-top: 1%;
}

.experience h4 {
    font-size: 30px;
    font-family: 'Jost', sans-serif;
    line-height: 3rem;
    margin: 0px 10px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .experience h4 {
        font-size: 20px;
        font-family: 'Jost', sans-serif;
        line-height: 2rem;
        padding: 20px;
        text-align: center;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .experience h4 {
        font-size: 30px;
        font-family: 'Jost', sans-serif;
        line-height: 3rem;
        margin-left: 30px;
        padding-top: 0px;
    }
}

.experience h6 {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Mukta', sans-serif;
    padding-top: 35px;
    margin-left: 30px;
}

.group-div {
    margin-top: 40px;
    padding: 40px;
    justify-content: space-between;
}

.icons {
    width: 30%;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .icons {
        width: 50%;
        margin-top: -100px !important;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .icons {
        width: 50px;
    }
}

.group-div h2 {
    color: rgb(65, 62, 62);
    font-size: 35px;
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .group-div h2 {
        color: rgb(65, 62, 62);
        font-size: 25px;
    }
}

.group-div h5 {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    color: #4e704d;
}

.banner-div h3 {
    position: absolute;
    top: 30%;
    left: 10%;
    text-align: start;
    max-width: 600px;
    font-size: 50px;
    font-family: 'Russo One', sans-serif;
    color: rgb(7, 0, 0);
}


.product-heading h4 {
    text-align: center;
    font-size: 50px;
    font-family: 'Oswald', sans-serif;
    padding: 70px 0 0 30px;
}


@media only screen and (min-width: 300px) and (max-width: 900px) {
    .product-heading h4 {
        text-align: center;
        font-size: 30px;
        font-family: 'Oswald', sans-serif;
        padding: 30px 0 0 10px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1023px) {
    .product-heading h4 {
        text-align: center;
        font-size: 30px;
        font-family: 'Oswald', sans-serif;
        padding: 70px 0 0 30px;

    }
}

.brands-div h1 {
    text-align: center;
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    padding: 70px 0 0 30px;

}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .brands-div h1 {
        text-align: center;
        font-size: 30px;
        font-family: 'Oswald', sans-serif;
        padding: 70px 0 0 30px;
    
    }
}
.why-para {
    font-size: 25px;
    padding-top: 50px;
    text-align: start;
    font-family: 'Edu TAS Beginner', cursive;
    margin-left: 90px;
}

.contact-div {
    background-color: rgb(179, 183, 184);
    margin: 50px 0px 50px 0px;
}

.contact-sub {
    margin-top: 30px;
    font-family: 'Handlee', cursive;
    font-size: 25px;
}

@media only screen and (min-width: 700px) and (max-width: 1024px) {
    .contact-sub {
        margin-top: 30px;
        font-family: 'Handlee', cursive;
        font-size: 20px;
    }
}

.contact-details {
    width: 100px;
    height: 100px;
    background-color: white;
    align-items: center;
}

.location-icon {
    width: 90px;
    height: 80px;
    border-radius: 50%;
}

.contact-data {
    font-family: sans-serif;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
}

@media only screen and (min-width: 700px) and (max-width: 1024px) {
    .contact-data {
        font-family: sans-serif;
        margin-top: 20px;
        font-weight: 600;
        font-size: 15px;
    }
}


.work-div {
    margin-top: 80px;
    padding: 80px;
    width: 100%;
    background-color: aliceblue;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .work-div {
        margin-top: 10px;
        padding: 10px !important;
        width: 100%;
        background-color: aliceblue;
    }
}

.work-div h1 {
    text-transform: uppercase;
    font-family: 'Fjalla One', sans-serif;
    font-size: 50px;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .work-div h1 {
        text-transform: uppercase;
        font-family: 'Fjalla One', sans-serif;
        font-size: 30px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .work-div h1 {
        text-transform: uppercase;
        font-family: 'Fjalla One', sans-serif;
        font-size: 40px;
    }
}

.process-div {
    background-color: white;
    margin-top: 60px;
    transition: transform .2s;
    margin-left: 30px;
}

.process-div:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 6px -6px #eee6e6;
}

.process-div img {
    width: 40%;
    align-items: start;
}

.process-div h3 {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    color: #4e704d;
    text-align: start;
    margin-left: 20px;
}

.process-div p {
    text-align: start;
    margin: 20px 10px 20px 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
}

.brand-div {
    margin-top: 10%;
    padding-bottom: 40px;
}

.brand-div h1 {
    text-transform: capitalize;
    font-family: 'Gruppo', sans-serif;
    text-align: center;
    font-weight: 600;
}

.carousel-img {
    margin-top: 60px;
}

.services-card p {
    font-size: 15px;
    text-transform: capitalize;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


@media only screen and (min-width: 300px) and (max-width: 699px) {
    .services-card {
        margin-top: 40px;
        margin-left: 0px !important;
        transition: transform .2s;
        margin-bottom: 40px;
        width: 100%;
    }


}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .services-card {
        margin-top: 40px;
        margin-left: 0px !important;
        margin-right: 20px;
        transition: transform .2s;
        margin-bottom: 40px;
        width: 100%;
    }


}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .banner-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .banner-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .network-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .gate-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .porch-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .door-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .porch-div img {
        height: 200px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .office-div img {
        height: 200px;
    }
}