@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:wght@400;700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');

.network-banner {
    position: relative;
}

.network-banner img {
    width: 100%;
    height: 500px;
    opacity: 70%;
}

.network-div h2{
    position: absolute;
    top: 30%;
    left: 28%;
    font-size: 60px;
    text-transform: uppercase;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .network-div h2 {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        /* text-align: start; */
    }
}

@media only screen and (min-width: 600px) and (max-width: 1023px) {
    .network-div h2 {
        position: absolute;
        top: 30%;
        left: 28%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}
.network-banner h1 {
    text-transform: capitalize;
    position: absolute;
    top: 30%;
    left: 35%;
    font-size: 6em;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.network-img {
    margin-left: 20px;
    border-radius: 10px;
    margin-top: 80px !important;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .net-p{
        line-height: 2rem !important;
        font-weight: 200 !important;
    }
}