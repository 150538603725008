.product-div {
    position: relative;
}

.product-div img {
    width: 100%;
    height: 500px;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .product-div img {
        width: 100%;
        height: 250px;
    }
}
.product-div h2 {
    position: absolute;
    top: 20%;
    left: 30%;
    font-size: 60px;
    text-transform: uppercase;
    color: white;
    font-family: 'Roboto', sans-serif;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .product-div h2 {
        position: absolute;
        top: 20%;
        left: 10%;
        font-size: 20px;
        text-transform: uppercase;
        color: white;
        font-family: 'Roboto', sans-serif;
    }
}
.products {
    margin-top: 50px;
    box-shadow:3px 5px 18px 1px  #a79d9d;
    background-color: white;
    padding: 30px;
    margin-bottom: 8%;
}

.products h3 {
    font-family: 'Inter', sans-serif;
    text-align: start;
    text-transform: capitalize;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .products h3 {
        font-family: 'Inter', sans-serif;
        text-align: start;
        text-transform: capitalize;
        font-size: 20px;
    }
}
.product-card  {
   margin-top: 50px;
   width: 100%;
   margin-right: 30px;
   margin-bottom: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .product-card  {
        margin-top: 50px;
        width: 100%;
        margin-left: 0px !important;
        margin-bottom: 30px;
     }
}

.product-card img {
    padding: 20px;
}
.cards-product {
    margin-top: 5%;
}

.cards-product  h2 {
    font-size: 50px;
    font-weight: 600;
    font-family: 'PT Serif', serif;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .cards-product  h2 {
        font-size: 30px;
        font-weight: 600;
        font-family: 'PT Serif', serif;
    }
}
.product-title {
    width: 150px;
    height: auto;
    background-color: #236621;
    box-shadow: 3px 5px 18px 1px  #a79d9d;
    text-align: center;
    padding: 15px;
    color: white;
    margin-left: 20%;
    border-radius: 10px;
}