@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Serif:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Josefin+Sans:wght@600&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald:wght@400;600&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins:ital,wght@0,400;0,700;1,100&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');

.service {
    width: 100%;
    height: 500px;
}

.cctv-div {
    position: relative;
}

.cctv-div h2 {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 55px;
    text-transform: uppercase;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    text-align: start;
}

.cctv-div p {
    position: absolute;
    top: 33%;
    left: 30%;
    color: white;
    font-size: 28px;
    text-align: start;
    font-family: 'PT Sans', sans-serif;
}
.object-div {
    padding-top: 5%;
}
.object-div h1{
   font-size: 50px;
   text-align: start;
   margin-left: 80px;
   text-transform: capitalize;
   font-family: 'Anton', sans-serif;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .object-div h1{
        font-size: 30px;
        text-align: start;
        text-transform: capitalize;
        font-family: 'Anton', sans-serif;
        padding-top: 50px;
     }
    

}
.description {
    padding-top: 50px;
}

.description img {
    width: 100%;
    border-radius: 10px;
    height: 700px;
    margin-top: 30px;
    margin-left: 20px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
  
.description img {
    border-radius: 10px;
    margin-top: -30px;
    margin-left: 5px;
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
    .description img {
        width: 100%;
        height: 1200px;
    }
}  

}
.description p {
    text-align: start;
    font-size: 25px;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 3rem;
    margin-left: 50px;
    margin-top: -10px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .description p {
        text-align: start;
        font-size: 15px;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 2rem;
        margin-left: 10px;
        margin-top: 10px;
    }
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
    .description p {
        text-align: start;
        font-size: 15px;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 2rem;
        margin-left: 10px;
        margin-top: 10px;
    }
}

.types-of-service {
    margin-top: 5%;
    padding-bottom: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .types-of-service {
        margin-top: -20px;
        padding-bottom: 30px;
    }
}

.types-of-service h1 {
    text-transform: capitalize;
    font-family: 'Gruppo', sans-serif;
    text-align: center;
    font-weight: 600;
    padding-bottom: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .types-of-service h1 {
        text-transform: capitalize;
        font-family: 'Gruppo', sans-serif;
        text-align: center;
        font-weight: 600;
        padding-bottom: 10px;
        margin-left: -20px;
    }
}
.services-card {
    margin-top: 40px;
    margin-left: 30px;
    transition: transform .2s;
    margin-bottom: 40px;
    text-transform: capitalize;
}

.services-card:hover{
    box-shadow:0 8px 6px -6px  #7a7272;
    border: none;
    transform: scale(1.05); 
}
.services-card h4 {
    font-size: 20px;
    font-weight: 600;
    padding-top: 20px;
    text-transform: capitalize;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.card-link {
    text-decoration: none;
}

.service-images {
    width: 100%;
    border-radius: 10px;
}
.brand-div {
    margin-top: 10%;
    padding-bottom: 40px;
}
.brand-div h1 {
    text-transform: capitalize;
    font-family: 'Gruppo', sans-serif;
    text-align: center;
    font-weight: 600;
}

.carousel-img {
   margin-top: 60px;
}