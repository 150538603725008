.porch-div h2 {
    position: absolute;
    top: 30%;
    left: 20%;
    font-size: 55px;
    text-transform: uppercase;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    text-align: start;
}

@media only screen and (min-width: 300px) and (max-width: 699px) {
    .porch-div h2 {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1023px) {
    .porch-div h2 {
        position: absolute;
        top: 30%;
        left: 20%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}