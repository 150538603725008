    .white-div {
    background-color: rgb(199, 196, 196);
    margin-top: 50px;
    padding: 50px;
  }
  
  .white-div img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .white-div h1 {
    font-size: 60px;
    text-align: start;
    font-family: 'Oswald', sans-serif;
    margin-top: 20px;
  }
  
  .white-div p {
    text-align: start;
    font-size: 25px;
    padding-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .boxes {
    margin-top: 50px;
  }
  
  .box1 {
    text-align: start;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .box2 {
    text-align: start;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    height: auto;
    margin-bottom: 20px;
  }
  .box3 {
    text-align: start;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    height: auto;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 700px) and (max-width: 1023px) {
    .box1 {
      text-align: start;
      padding: 30px;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 22px;
      height: auto;
      margin-bottom: 20px;
      width: 800px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .box2 {
    text-align: start;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    height: auto;
    margin-bottom: 20px;
    width: 800px;
    margin-top: 35rem;
    margin-left: -15rem;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .box3 {
    text-align: start;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    height: auto;
    margin-bottom: 20px;
    width: 800px;
    margin-top: 68rem;
    margin-left: -30rem;
    font-weight: 800;
  }
}