@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');

.contact-main-div {
    position: relative;
}

.contact-main-div img{
    width: 100%;
    opacity: 80%;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contact-main-div img{
        height: 200px;
        opacity: 80%;
    }
}

.contact-main-div h1 {
    position: absolute;
    top: 20%;
    left: 38%;
    text-align: start;
    font-size: 80px;
    font-family: 'Barlow Condensed', sans-serif;  
    text-transform: uppercase;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contact-main-div h1 {
        position: absolute;
        top: 10%;
        left: 35%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .contact-main-div h1 {
        position: absolute;
        top: 10%;
        left: 10%;
        font-size: 25px;
        text-transform: uppercase;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        text-align: start;
    }
}

.contacts {
    padding: 80px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contacts {
        padding: 30px;
    }
}
.contact-items {
    padding: 50px;
    background-color: #f8f8f8;
    justify-content: space-around;
    margin-top: 50px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contact-items {
        padding: 50px;
        background-color: #f8f8f8;
        justify-content: space-around;
        margin-top: 30px;
    }
}
.contact-items h4 {
    font-family: 'Barlow Condensed', sans-serif;  
    margin-top: 30px;
}

.contact-items p {
    margin-top: 30px;
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
    color: rgb(99, 91, 91);
    cursor: pointer;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .contact-items p {
        margin-top: 30px;
        font-size: 15px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
        color: rgb(99, 91, 91);
        cursor: pointer;
    }
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .email {
        margin-left: -40px;
        font-size: 10px;
    }
}


@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .contact-items p {
        margin-top: 30px;
        font-size: 18px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
        color: rgb(99, 91, 91);
        cursor: pointer;
    }
}
.forms {
    background: #ffffff;
    box-shadow: 0px 0px 139px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    padding-bottom: 50px;
    width: 60%;
    position: relative;
    border-radius: 25px;
    padding: 50px;
}

.forms p {
    text-transform: uppercase;
    font-size: 20px;
    color: blue;
    padding-top: 30px;
    font-family: 'Barlow Condensed', sans-serif; 
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .forms p {
        text-transform: uppercase;
        font-size: 15px;
        color: blue;
        padding-top: 30px;
        font-family: 'Barlow Condensed', sans-serif; 
    }
}
.forms h4 {
    font-size: 50px;
    font-family: 'Barlow Condensed', sans-serif; 
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .forms h4 {
        font-size: 30px;
        font-family: 'Barlow Condensed', sans-serif; 
    }
}
.name {
    max-width: 100%;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid gray;
    margin-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .name {
        width: 900px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid gray;
        margin-top: 50px;
        font-family: Arial, Helvetica, sans-serif;
    }
}
.subject {
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
    height: 80px;
    display: flex;
    align-items: start;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Arial, Helvetica, sans-serif;
}

.message {
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
    height: 180px;
    display: flex;
    align-items: start;
    border-radius: 5px;
    border: 1px solid gray;
    font-family: Arial, Helvetica, sans-serif;
}

.msg-btn  {
    background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
    display: inline-block;
    overflow: hidden;
    transform: perspective(0) translateZ(0);
    border-color: transparent;
    color: #ffffff;
    border-radius: 5px;
    font-size: 18px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    height: 60px;
    width: 70%;
    line-height: 56px;
    padding: 0px 70px;
    border: 0;
    border-style: solid;
    box-shadow: none;
    transition: all 0.5s ease 0s;
    cursor: pointer;
    z-index: 1;
    text-align: center;
    margin-top: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 699px) {
    .msg-btn  {
        background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
        display: inline-block;
        overflow: hidden;
        transform: perspective(0) translateZ(0);
        border-color: transparent;
        color: #ffffff;
        border-radius: 5px;
        font-size: 18px;
        font-family: "Barlow", sans-serif;
        font-weight: 700;
        height: 60px;
        width: 70%;
        line-height: 56px;
        padding: 0px 30px;
        border: 0;
        border-style: solid;
        box-shadow: none;
        transition: all 0.5s ease 0s;
        cursor: pointer;
        z-index: 1;
        text-align: center;
        margin-top: 30px;
    }
}

.gmap {
    width: 100%;
    margin-top: -8%;
}

