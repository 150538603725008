.card-items {
    gap: 80px;
    padding:50px 50px 0 0;
    transition: transform .2s;
    /* margin-left: 300px; */
    margin-bottom: 30px;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .card-items {
        gap: 80px;
        padding:10px 0 0 0;
        transition: transform .2s;
        margin-bottom: 30px;
    }
}
.card {
    border: none;
    box-shadow: 0px 5px 15px 0px #c9d2da;
    margin-left: 40px;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .card {
        border: none;
        box-shadow: 0px 5px 15px 0px #c9d2da;
        margin-left: 0px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1023px) {
    .card {
        border: none;
        box-shadow: 0px 5px 15px 0px #c9d2da;
        margin-left: 0px;
    }
}


.card-title {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.card-items:hover {
    transform: scale(1.05); 
    box-shadow:0 8px 6px -6px  #eee6e6;
}

.card-items button {
    color: white;
    background-color:#236621;
    width: 100px;
    margin: 20px 120px;
    border: none;
    text-transform: uppercase;
}
@media only screen and (min-width: 300px) and (max-width: 900px) {
    .card-items button {
        color: white;
        background-color:#236621;
        width: 100px;
        margin: 20px 135px;
        border: none;
        text-transform: uppercase;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1023px) {
    .card-items button {
        color: white;
        background-color:#236621;
        width: 100px;
        margin: 20px 87px;
        border: none;
        text-transform: uppercase;
    }
}
.card-items button:hover{
    background-color: #1dc517;
    border: none;
}