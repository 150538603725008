@import url('https://fonts.googleapis.com/css2?family=Acme&family=Anton&family=Barlow+Condensed:wght@500&family=Cabin:ital,wght@1,600&family=Crimson+Text:wght@600&family=DM+Sans:ital,opsz,wght@0,9..40,600;1,9..40,200&family=Edu+TAS+Beginner&family=Fjalla+One&family=Gruppo&family=Handlee&family=Inter:wght@500&family=Mukta:wght@300&family=Noto+Sans+JP:wght@500&family=Noto+Sans+Kawi&family=Oswald&family=Oxygen:wght@700&family=PT+Sans:ital@1&family=PT+Serif:wght@700&family=Poppins&family=Roboto:ital,wght@0,100;0,400;1,100&family=Rubik:wght@400;500&family=Russo+One&family=Vina+Sans&display=swap');


.logo-div img {
  width: 80%;
}



.topbar {
  background-color: #4e704d;
  position: sticky;
  top: 0;
  z-index: 100;
}

.heading {
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  color: white;
  text-align: start;
}

.span-arabi {
  font-size: 29px;
}

.heading:hover {
  color: white;
}

.nav-items {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: white;
  margin-right: 50px;
  margin-top: -5px;
}

.nav-link {
  color: white;
  margin-left: 50px;
}

.navbar-nav .nav-link.show {
  color: white;
}

.nav-link:focus,
.nav-link:hover {
  color: white;
}


/*  */
/* responsive */
/*  */


@media only screen and (min-width: 300px) and (max-width: 699px)  {
  .logo-div img {
    width: 80%;
    margin-left: -30px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .logo-div img {
    width: 80%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1470px){
  .logo-div img {
    width: 80%;
  }
}


@media only screen and (min-width: 300px) and (max-width: 699px)  {
  .span-arabi {
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .span-arabi {
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1470px){
  .span-arabi {
    font-size: 33px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
  }
}

@media only screen and (min-width: 300px) and (max-width: 699px)  {
  .heading {
    font-size: 11px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
    margin-left: -33px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .heading {
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
    margin-left: 0px !important;

  }
}
@media only screen and (min-width: 1024px) and (max-width: 1470px) {
  .heading {
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: white;
    text-align: start;
    margin-left: 80px;
    margin-left: 20px;

  }
}
@media only screen and (min-width: 300px) and (max-width: 699px)  {
  .nav-items {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: white;
    margin-right: 150px;
    margin-top: -5px;
    text-align: start;
  }
   
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .nav-items {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: white;
    margin-right: 150px;
    margin-top: -5px;
    text-align: start;
  }
  }

@media only screen and (min-width: 1024px) and (max-width: 1470px) {
  .nav-items {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: white;
    margin-right: 40px;
    margin-top: -5px;
  }
  
}


@media only screen and (min-width: 1024px) and (max-width: 1470px) {
  .nav-link {
    color: white;
    margin-left: 0px !important;
  }
  
}
